import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkoutDestination } from 'redux/modules/checkout/selectors/checkout';
import doInstantCheckout from 'redux/modules/instant-checkout/actions/do-instant-checkout';
import { finishedInstantCheckout } from 'redux/modules/instant-checkout/actions/instant-checkout-progress-status';
import {
  getIsConfirmingInstantCheckout,
  getIsInstantCheckoutIsInProgress,
} from 'redux/modules/instant-checkout/selectors';
import { getInstantCheckoutLocation } from 'redux/modules/trolley/selectors/can-instant-checkout';
import { goToInternalOrExternalLocation } from 'utils/go-to-internal-or-external-location';
import { dataLayer } from 'analytics/data-layer';

import styles from './InstantCheckoutModal.scss';

export default function InstantCheckoutModal() {
  const isConfirming = useSelector(getIsConfirmingInstantCheckout);
  const inProgress = useSelector(getIsInstantCheckoutIsInProgress);
  const regularCheckoutDestination = useSelector(checkoutDestination);
  const instantCheckoutLocation = useSelector(getInstantCheckoutLocation);

  const dispatch = useDispatch<WtrDispatch>();

  const sendInstantCheckoutEvent = (eventType: string, location: string) => {
    dataLayer.push({
      event: eventType,
      instant_checkout: {
        location,
      },
    });
  };

  const closeModal = useCallback(
    (closeWithX = true) => {
      dispatch(finishedInstantCheckout());

      if (closeWithX) {
        sendInstantCheckoutEvent('instant_checkout_close', instantCheckoutLocation);
      }
    },
    [dispatch, instantCheckoutLocation],
  );

  const goToRegularCheckout = useCallback(() => {
    goToInternalOrExternalLocation(regularCheckoutDestination);
    closeModal(false);
    sendInstantCheckoutEvent('review_checkout_click', instantCheckoutLocation);
  }, [closeModal, instantCheckoutLocation, regularCheckoutDestination]);

  const instantCheckoutClick = useCallback(() => {
    dispatch(doInstantCheckout()).then(() => closeModal(false));
    sendInstantCheckoutEvent('instant_checkout_click', instantCheckoutLocation);
  }, [closeModal, dispatch, instantCheckoutLocation]);

  return (
    <Modal
      titleText="Check out the way you want"
      messageText={
        inProgress
          ? undefined
          : [
              'Check out in seconds with Instant Checkout - or check out as normal to shop your missed favourites, add vouchers and more',
            ]
      }
      closeModal={closeModal}
      buttons={
        inProgress
          ? []
          : [
              {
                buttonText: 'Check out as normal',
                closeOnClick: false,
                onClick: goToRegularCheckout,
              },
              {
                buttonText: 'Instant checkout',
                closeOnClick: false,
                onClick: instantCheckoutClick,
              },
            ]
      }
      isOpen={isConfirming}
      cannotClose={inProgress}
    >
      {inProgress && (
        <div className={styles.spinnerWrapper}>
          <Spinner isActive />
        </div>
      )}
    </Modal>
  );
}
